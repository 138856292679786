import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Logo from "../logo";
import { FaSpinner } from "react-icons/fa";
import styles from "../../styles/components/reset/resetPage.module.scss";
import { connect } from "react-redux";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "gatsby";
import { autoLogin } from "../../state/actions/auth";

const RecoverPage = (props) => {
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (pwd) {
      if (pwd === confirmPwd) {
        setIsLoading(true);
        setMessage("");
        try {
          const res = await axios.post(
            `${process.env.GATSBY_API_CALL}/reset/${props.token}`,
            {
              password: pwd,
              confirmPassword: confirmPwd,
            }
          );
          props.autoLogin(res.data);
          setIsLoading(false);
          setSuccess(true);
        } catch (e) {
          setMessage(e.response.data.message);
          setIsLoading(false);
        }
      } else {
        setMessage("Passwords do not match!");
      }
    }
  };

  return (
    <Container className={styles.container}>
      <div className={styles.resetForm}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <h1 className={`${styles.title} h1`}>
          <span>Reset</span> Password
        </h1>
        {success ? (
          <div className="text-center">
            <h1 className={`${styles.successIcon} h1`}>
              <AiOutlineCheckCircle />
            </h1>
            <p className={`${styles.successTitle}`}>
              Password successfully changed!
            </p>
            <Link to="/">
              <Button size="lg">Start Shopping</Button>
            </Link>
          </div>
        ) : (
          <>
            <p className={styles.message}>{message}</p>
            <p>Set a new password for your account.</p>
            <Form className={`${styles.formDiv} text-center`}>
              <Form.Group>
                <Form.Control
                  type="password"
                  className={`text-center ${styles.inputBox}`}
                  size="lg"
                  value={pwd}
                  placeholder="New password"
                  onChange={(e) => setPwd(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  className={`text-center ${styles.inputBox}`}
                  value={confirmPwd}
                  placeholder="Confirm new password"
                  onChange={(e) => setConfirmPwd(e.target.value)}
                />
              </Form.Group>
              <Button onClick={submitHandler} size="lg" type="submit">
                {isLoading ? <FaSpinner className="spinner" /> : "Submit"}
              </Button>
            </Form>
          </>
        )}
      </div>
    </Container>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    autoLogin: (data) => dispatch(autoLogin(data)),
  };
};

export default connect(undefined, mapDispatchToProps)(RecoverPage);
