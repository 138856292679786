import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Logo from "../logo";
import { toast } from "react-toastify";

import styles from "../../styles/components/reset/resetPage.module.scss";
import { FaSpinner } from "react-icons/fa";

const ResetPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (email) {
      setIsLoading(true);
      setMessage("");
      try {
        await axios.post(`${process.env.GATSBY_API_CALL}/recover`, {
          email: email.trim().toLowerCase(),
        });
        setMessage(
          "A password reset link has been sent to your email address!"
        );
        setIsSubmit(true);
        setIsLoading(false);
      } catch (e) {
        toast.error("Something went wrong, please try again!");
        if (e.response) {
          setMessage(e.response.data.message);
        }
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Container className={styles.container}>
      <div className={styles.resetForm}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <h1 className={`${styles.title} h1 text-center`}>
          <span>Reset</span> Your Password
        </h1>
        {isSubmit ? (
          <div>
            <h1 className={`${styles.successIcon} h1`}>
              <AiOutlineCheckCircle />
            </h1>
            <p className={`${styles.successTitle}`}>{message}</p>
          </div>
        ) : (
          <div className="text-center">
            <p className={styles.message}>{message}</p>
            <p>
              Forgot your password? Don't worry. We'll send you an email to
              reset your password.
            </p>
            <Form>
              <Form.Control
                type="email"
                className={`${styles.inputBox} text-center`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email Address"
              />
              <Button
                size="lg"
                onClick={submitHandler}
                disabled={!email || isLoading}
                style={{ width: "180px" }}
                type="submit"
              >
                {isLoading ? <FaSpinner className="spinner" /> : "Submit"}
              </Button>
            </Form>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ResetPage;
