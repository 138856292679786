import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import ResetPage from "../components/reset/resetPage";
import RecoverPage from "../components/reset/recoverPage";

const App = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Layout>
      {!isLoading && (token ? <RecoverPage token={token} /> : <ResetPage />)}
    </Layout>
  );
};

export default App;
